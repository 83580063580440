export const ObsoleteIcon = props => {
	return (
		<svg
			className={props.className}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 200 200"
		>
			<path
				className="gear"
				d="M158.9,93.7c-0.1-1.3-0.3-2.5-0.5-3.7c-0.2-1.3-0.5-2.7-0.9-4c-0.5-2-2.1-3.5-4.1-3.7l-7.3-1
		c-1.7-4.2-4-8-6.7-11.6l2.8-6.8c0.8-1.9,0.3-4.1-1.2-5.5c-0.9-0.9-1.9-1.7-2.8-2.5c-1-0.9-2.1-1.7-3.2-2.5
		c-1.7-1.2-3.9-1.3-5.6-0.2l-6.3,4c-3.9-2-8.1-3.6-12.5-4.5l-2.3-7.1c-0.6-1.9-2.4-3.3-4.4-3.4c-1.3-0.1-2.5-0.1-3.8-0.1
		c-1.4,0-2.7,0-4.1,0.1c-2,0.1-3.8,1.5-4.4,3.4l-2.3,7.2c-4.3,1-8.5,2.5-12.3,4.5l-6.4-4.1c-1.7-1.1-3.9-1-5.6,0.2
		c-1,0.8-2,1.5-3,2.3c-1,0.9-2.1,1.8-3.1,2.7c-1.5,1.4-1.9,3.6-1.2,5.5l2.9,7.1c-2.6,3.4-4.8,7.2-6.5,11.3l-7.6,1
		c-2,0.3-3.7,1.8-4.2,3.8c-0.3,1.2-0.6,2.5-0.8,3.7c-0.2,1.3-0.4,2.7-0.6,4.1c-0.2,2,0.8,4,2.6,4.9l6.8,3.6c0.2,4.4,1,8.7,2.2,12.8
		l-5.2,5.7c-1.4,1.5-1.7,3.7-0.8,5.5c0.6,1.1,1.2,2.2,1.8,3.3c0.7,1.2,1.4,2.3,2.2,3.5c1.1,1.7,3.2,2.5,5.2,2.1l7.5-1.6
		c2.9,3.2,6.3,6,10,8.4l-0.3,7.6c-0.1,2,1.1,3.9,3,4.7c1.2,0.5,2.3,1,3.5,1.4c1.3,0.5,2.6,0.9,3.9,1.3c2,0.6,4.1-0.1,5.3-1.7l4.6-6
		c2.2,0.3,4.4,0.5,6.7,0.5s4.3-0.2,6.5-0.4l4.6,5.9c1.2,1.6,3.4,2.3,5.3,1.7c1.2-0.4,2.4-0.8,3.6-1.2c1.3-0.5,2.6-1,3.8-1.5
		c1.9-0.8,3-2.7,3-4.7l-0.3-7.4c3.8-2.4,7.2-5.2,10.2-8.5l7.2,1.6c2,0.4,4.1-0.4,5.2-2.1c0.7-1,1.4-2.1,2-3.2
		c0.7-1.2,1.3-2.4,1.9-3.6c0.9-1.8,0.6-4-0.8-5.5l-4.9-5.4c1.3-4.2,2.1-8.6,2.3-13.2l6.5-3.4C158.1,97.7,159.1,95.7,158.9,93.7
		L158.9,93.7z M100.1,134.9c-19.2,0-34.8-15.6-34.8-34.8s15.6-34.8,34.8-34.8S135,80.8,135,100.1S119.4,134.9,100.1,134.9z"
			/>
			<g id="update">
				<path className="st1" d="M55.3,34.6c36.1-24.7,85.4-15.4,110.1,20.7" />
				<path className="st1" d="M144.7,165.4c-36.1,24.7-85.4,15.4-110.1-20.7" />
				<path
					className="st2"
					d="M184,76.4l-1.9-25.2c-0.2-3.1-3.7-4.7-6.2-3L155,62.4c-2.5,1.7-2.3,5.6,0.5,6.9l22.8,11
		C181.1,81.6,184.3,79.5,184,76.4z"
				/>
				<path
					className="st2"
					d="M16,123.7l1.9,25.2c0.2,3.1,3.7,4.7,6.2,3l20.9-14.3c2.5-1.7,2.3-5.6-0.5-6.9l-22.8-11
		C19,118.5,15.8,120.7,16,123.7z"
				/>
			</g>
		</svg>
	);
};
